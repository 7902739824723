<template>
  <div>
    <div class="commonInputTopBox">
      <pageHeader :pagename="'云端记录'" />

      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="搜索">
          <el-input placeholder="AGV ID" v-model="keyword"></el-input>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button type="primary" @click="getList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="layoutTabaleBorder">
      <el-table :data="queryData.tableData" style="width: 100%">
        <el-table-column prop="garage_id" label="车库id" width="200"> </el-table-column>

        <el-table-column prop="garage.company_status" label="车库状态"> </el-table-column>

        <el-table-column prop="kafka_data.create_time" label="任务创造时间">
        </el-table-column>

        <el-table-column prop="license_plate" label="车牌号"> </el-table-column>
        <el-table-column prop="re_send_count" label="发送数据"> </el-table-column>
        <el-table-column prop="task_status" label="任务状态"> </el-table-column>
        <el-table-column prop="task_type" label="任务类型"> </el-table-column>
        <el-table-column prop="revc_status" label="接收状态"> </el-table-column>

        <!-- <el-table-column prop=" re_send_count" label="发送数据">
        </el-table-column> -->

        <el-table-column label="操作" min-width="180">
          <template slot-scope="scope">
            <el-dropdown type="primary">
              <el-button type="primary">
                更多<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="item in tableDropdown"
                  :key="item.name"
                  @click.native="handle(scope.row, item.name)"
                  >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="layoutBorder">
      <Pagination
        :total="queryData.total"
        :page.sync="queryData.currentPage"
        @pagination="getList()"
        :limit.sync="queryData.pageSize"
      />
    </div>
  </div>
</template>

<script>
import { task_now_get, task_now_re_create } from "@/api/manage/operation/taskmanage/task.js";

import Pagination from "@/components/Pagination.vue";

export default {
  name: "start",
  data() {
    return {
      keyword: null,
      queryData: {
        tableData: [],
        currentPage: 1,
        total: 0,
        pageCount: 100,
        pageSize: 100,
      },
      tableDropdown: [
        {
          id: "0",
          name: "重新发送",
        },
      ],
    };
  },
  components: {
    Pagination,
  },
  created() {
    this.getList(); // 定义该函数在页面一加载时就执行
  },
  methods: {
    handle(row, methods) {
      // console.log(row, methods);
      if (methods == "重新发送指定任务") {
        //不知道干啥，先写一下
        this.resend(row.task_id);
      }
    },
    resend(task_id) {
      let temp = {
        task_id: task_id,
      };
      task_now_re_create(temp).then((res) => {
        console.log(res,"数据重发")
        if (res.code == 200) {
          this.$message.success("数据重发成功");
        } else {
          console.log(res);
          this.$message.info("数据重发失败");
        }
      });
    },
    // 获取接口数据的方法
    getList() {
      task_now_get({
        querypage: this.queryData.currentPage,
        pagesize: this.queryData.pageSize,
        garage_id: "",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.queryData.total = res.data.total;
          this.queryData.currentPage = res.data.currentPage;
          this.queryData.pageCount = res.data.pageCount;
          this.queryData.tableData = res.data.result;
        } else {
          this.$message({
            message: res.msg,
            type: "error",
            duration: 5 * 1000,
          });
        }
      });
    },
    toAgvInfo(ids) {
      this.$router.push({
        name: "agvManageInfo",
        query: {
          ids: ids,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
