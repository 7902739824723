import request from '@/utils/request'

// 一级-车辆云端记录-查询
export function task_get(data) {
    return request({
        url: '/platform_api/manage_api/dispatch_task/query',
        method: 'post',
        data: data
    })
}

// 一级-车辆云端记录-查询
export function task_info(data) {
    return request({
        url: '/platform_api/manage_api/dispatch_task/info',
        method: 'post',
        data: data
    })
}


// 一级-接收状态-更改
export function task_update(data) {
    return request({
        url: '/platform_api/manage_api/dispatch_task/change_revc_status',
        method: 'post',
        data: data
    })
}

// 一级-正在下发的任务-查找
export function task_now_get(data) {
    return request({
        url: '/platform_api/manage_api/dispatch_task/insend',
        method: 'post',
        data: data
    })
}
// 一级-重新发送下发的任务-增加？
export function task_now_re_create(data) {
    return request({
        url: '/platform_api/manage_api/dispatch_task/re_send',
        method: 'post',
        data: data
    })
}

